
  .slideIn {
    transition: slideIn 0.5s ease;
  }
  
  .slideOut {
    transition: slideOut 0.5s ease;
  }
  
  .fadeIn {
    animation: fadeIn 0.5s ease-in-out forwards;
  }
  
  .fadeOut {
    transition: fadeOut 0.5s ease;
  }

  .fadeInOut {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .fadeInOut:hover {
    opacity: 1;
  }
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}