@import url('Animations.css');

.App {
  overflow: hidden;
}
.App-header {
  background-color: rgba(30, 23, 45, 0.5);
   min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.carousel.slide {
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;

}

i {
  color: white;
  font-size: 3rem;
  padding: 1vw;
  cursor: pointer;
}

i:hover {
  color: rgb(255, 101, 222);
  animation: spin 0.25s;
}
.icons {
  display: flex;
  flex-wrap: wrap;
}

.dropdown-link {
  color: black !important;
  text-decoration: none !important;
}
.intro-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.center-logo {
  display: none;
}

.placeholder {
  height: 90px;
}

a {
  color: white !important;
  text-decoration: none !important;
}
.image-list {
  overflow: hidden;
}

.img {
  border-radius: 5px;
  cursor: pointer;
}

.img:hover {
  transform: scale(1.05);
}

.pageOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

img.carousel {
  cursor: pointer;
}

.me {
  -webkit-filter: drop-shadow(12px 12px 7px rgba(0,0,0,0.5)) !important;
  filter: drop-shadow(12px 12px 7px rgba(0,0,0,0.5)) !important;
}

.page {
  animation: fadeIn 0.5s ease-in-out !important;
  transition: animation 0.3s ease-in-out;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(33, 24, 51, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
  display: flex;
  z-index: 999;
}

.overlay:hover {
  animation: fadeIn 0.5s;
  cursor: pointer;
  display: flex !important;
}

.overlay::after{
  opacity: 1;
}
.image-list p {
  margin: 0;
  font-size: 0.5em;
}

.close-btn {
  position: absolute;
  top: 40px;
  right: 40px;
  padding: 5px;
  background-color: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 20px;
  z-index: 999;
}

.featurelist {
  color: rgb(255, 101, 222)
}

.techlist {
  color: rgb(46, 213, 255)
}


.project-title  {
  font-size: 3.5em;
  margin-right: auto; 
  align-self: flex-end;
  margin-bottom: 0;
}

@media (max-width: 1200px) {
  .icons i {
    font-size: 2rem;
    padding: 0.5vw;
  }
}

@media (max-width: 1000px) {
  .icons i  {
    font-size: 2rem;
    padding: 0.5vw;
  }
}

@media (max-width: 900px) {
  .logo {
    display: none;
  }

  .center-logo {
    display: block;
  }
}

@media (max-width: 768px) {
  .carousel {
    width: 100%;
  }
}

@media (max-width: 500px) {
  p {
    font-size: 1rem !important;
  }
  li {
    font-size: 0.8rem !important;
  }
}